<template>
  <div class="container py-5">
    <p class="fw-bold">Workshop at AMWC 2021</p>
    <p>時間：2021 05/02 13:00-14:00</p>
    <p>地點：North Lounge Level3</p><br>
    <p>
      <b>Moderator：Dr. Chrang-shi LIN 林長熙</b><br><br> 

      <b>Speaker：Dr. Jeff Chen-Chieh Huang 黃政傑</b><br>
      Topic：The unique advantage of new OBSERV 520x system in monitoring skin quality<br><br> 


      <b>Speaker：Dr. Shih-Ming Tseng 曾世鳴</b><br> 
      Topic：Effectively guide the natural initiation of aesthetic medicine needs - The use of imaging system<br><br> 

      <b>Speaker：Dr. Chili Chih-Wei Li 李至偉</b><br>
      Topic：A Novel Way to Optimize the Outcome of injectable by AI-assisted action unit recognition<br><br> 
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_02.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
